<template>
  <v-container>
    <v-expansion-panels accordion multiple>
      <v-expansion-panel v-for="(r,datum,i) in wertungen" :key="i">
        <v-expansion-panel-header>
          <div class="d-flex">
            <div class="text-h6">{{ r.turnier }} </div>
            <v-spacer></v-spacer>
            <div class="text-caption pr-4 pt-1">{{ datum }}</div>
          </div>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          
          <div v-for="(sportler_in_klasse,klasse,ii) in r.klassen" :key="ii" class="mb-2">
            <div class="text-subtitle-2">{{ klasse }}</div>
            <table class="wertung">
              <thead>
                <tr>
                  <th class="text-left">Sportler</th>
                  <th style="width:65px">Gesamt</th>
                  <th style="width:30px">FNr.</th>
                  <th style="width:50px">ACC</th>
                  <th style="width:50px">PER</th>
                </tr>
              </thead>
              <tbody>
                <template v-for="wertungen in sportler_in_klasse">
                  <tr v-for="(wertung,iii) in wertungen" :key="iii">
                    <td v-if="iii === 0" :rowspan="wertungen.length" class="text-left">  
                      {{ wertung.name }}
                      <div class="text-caption">{{ wertung.verein }}</div>
                    </td>
                    <td v-if="iii === 0" :rowspan="wertungen.length" class="font-weight-bold text-center">{{ wertung.gesamtwertung }}</td>
                    <td class="text-center">{{ wertung.formnr }}</td>
                    <td>{{ wertung.ACC }}</td>
                    <td>{{ wertung.PER }}</td>
                  </tr>
                </template>
              </tbody>
            </table>
          </div>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </v-container>
</template>

<script>

import axios from 'axios';

  export default {
    name: 'meine_wertungen',

    data: () => ({
      data:[],
      loading: false,
    }),
    props:["id"],
    computed:{
      wertungen(){
        var d = {}
        for(var r of this.data){
          if (!d[r.turnierdatum]) d[r.turnierdatum] = {}
          d[r.turnierdatum]["turnier"] = r.turnier
          if (!d[r.turnierdatum]) d[r.turnierdatum] = {}
          if (!d[r.turnierdatum]["klassen"]) d[r.turnierdatum]["klassen"] = {}
          if (!d[r.turnierdatum]["klassen"][r.klasse]) d[r.turnierdatum]["klassen"][r.klasse] = {}
          if (!d[r.turnierdatum]["klassen"][r.klasse][r.Sportler]) d[r.turnierdatum]["klassen"][r.klasse][r.Sportler] = []
          d[r.turnierdatum]["klassen"][r.klasse][r.Sportler].push(r)
        }
        return d;
      }
    },
    methods:{
      async init() {
        this.loading = true
        await axios.get(process.env.VUE_APP_APIURL+"meine_wertungen/"+this.id,{
          headers: {
            'token': localStorage.getItem('token')
          }
        }).then((r) => {
          this.loading = false
          r = r.data
          console.log(r)
          if(r.result){
            this.data = r.result
          }else{
            this.error = r.error
          }
          //this.$router.push('/home')
        }).catch((error) => {
          this.loading = false
          this.error = error.message
        })
      },
    },
    mounted(){
      this.init()
    },
  }
</script>
<style scoped>
>>>.v-expansion-panel-header,
>>>.v-expansion-panel-content__wrap{
  padding-left: 10px;
  padding-right: 10px;
}

>>>.v-expansion-panel-header,
>>>.v-expansion-panel--active > .v-expansion-panel-header{
  padding-top: 10px;
  padding-bottom: 10px;
  min-height: auto;
}

.wertung{
  border-collapse: collapse !important;
}
.wertung th,
.wertung td{
  text-align: right;
  border:1px solid #888888;
  padding-right:5px;
  padding-left:5px;
}
</style>