<template>
  <v-container v-if="user.name">
    <v-dialog :value="mediadialog" hide-overlay fullscreen>
      <v-card>
        <v-toolbar dark color="primary">
          <v-toolbar-title>Anzeigen</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn dark text @click="current_video = false;current_image = false"><v-icon>mdi-close</v-icon></v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <video playsinline v-if="current_video" :src="current_video" controls autoplay width="100%"></video>
        <img v-if="current_image" :src="current_image" width="100%" height="100%" />
        <!--<div v-if="current_image" v-html="current_image"></div>
        <div v-if="current_video" v-html="current_video"></div>-->
      </v-card>
    </v-dialog>

    <div class="d-flex mb-2">
      <div class="text-h5 pt-1" v-html="user.name + ' ' + user.vorname"></div>
      <v-spacer></v-spacer>

      <v-menu offset-y>
        <template v-slot:activator="{ on, attrs }">
          <v-btn small color="primary"  v-bind="attrs" v-on="on" fab elevation="0">
            <v-icon>mdi-menu</v-icon>
          </v-btn>
        </template>
        <v-list>
          <v-list-item v-if="isTrainer" @click="edituser"><v-list-item-title>Sportler bearbeiten</v-list-item-title></v-list-item>
          <v-list-item><router-link class="routerlink black--text" :to="'/meine_wertungen/'+user.ID"><v-list-item-title>Meine Wertungen</v-list-item-title></router-link></v-list-item>
        </v-list>
      </v-menu>
    </div>
    <v-file-input v-if="isTrainer" hide-details label="Bild oder Video hochladen" :clearable="false" v-model="mediafile" :loading="uploading" @change="selectFile" filled prepend-icon="mdi-camera"></v-file-input>
    
    <v-timeline align-top dense>
      <v-timeline-item v-for="(item) in myItems" small dense :color="timelineColor(item)" class="pl-0" :key="item.id">
        <v-card :color="itemColor(item)" dark>
          <v-card-title class="text-subtitle-1 py-2">
            <v-icon v-if="item.filetype=='image'" left>mdi-camera</v-icon>
            <v-icon v-if="item.filetype=='video'" left>mdi-video</v-icon>
            <v-rating v-if="item.prio" :value="Number(item.prio)" :length="item.prio" readonly dense background-color="orange lighten-3" color="orange"></v-rating>
            <v-spacer></v-spacer>
            <v-btn dark icon x-small @click="editShowItem(item)"><v-icon>mdi-dots-vertical</v-icon></v-btn>
          </v-card-title>
          <v-card-text class="white text--primary pt-2">
            <v-row>
              <v-col cols="4">
                <v-avatar v-if="item.thumb"><img :src="item.thumb"></v-avatar>
              </v-col>
              <v-col cols="8" class="text-right">
                <v-btn color="green darken-1" v-if="isTrainer" @click="send(item)" class="ml-0 mr-3" fab small elevation="0">
                  <v-icon dark>mdi-whatsapp</v-icon>
                </v-btn>
                <v-btn :color="itemColor(item)" @click="ansehen(item)" class="mx-0" fab small elevation="0">
                  <v-icon v-if="item.filetype=='video'" dark>mdi-play</v-icon>
                  <v-icon v-else dark>mdi-image</v-icon>
                </v-btn>
                <div class="text-caption grey--text text-right">{{ item.d }} {{ item.t }}</div>
              </v-col>
            </v-row>

            
            <p class="mb-2" v-if="item.analyse" v-html="item.analyse"></p>
            <p class="mb-2" v-if="item.empfehlung" v-html="item.empfehlung"></p>
            
          </v-card-text>
        </v-card>
      </v-timeline-item>
    </v-timeline>

  </v-container>
</template>

<script>

import axios from 'axios';


//https://stackoverflow.com/questions/40974962/html-video-not-playing-with-vue-js-2
  export default {
    name: 'user',
    data: () => ({
      user:{},
      currentFile: undefined,
      progress: 0,
      message: "",
      uploading:false,
      mediafile: null,
      fileInfos: [],
      items: [],
      current_video: false,
      current_video_mime_type: false,
      current_image: false,
    }),
    props:["id"],
    computed:{
      mediadialog(){
        return this.current_video || this.current_image
      },
      myItems(){
        if (this.$route.params.itemtoken){
          return Array.from(this.items).filter( item  => item.itemtoken==this.$route.params.itemtoken);
        }
        return Array.from(this.items).reverse();
      },
      isTrainer(){
        if (window && window.myuser) return window.myuser.isTrainer
        return false;
      }
    },
    methods:{
      send(item){
        var url = "https://wa.me/"+this.user.tel_land+this.user.tel+"?text=Hallo%20"+this.user.vorname+",%20es%20gibt%20was%20Neues%20bei%20CoachMe%0a%0ahttps://training.x-trasoft.com/item/"+this.user.ID+"/"+item.itemtoken
        var a = document.createElement("a");
        a.href = url;
        window.document.body.appendChild(a)
        a.click();
        window.document.body.removeChild(a);
        return true;
      },
      timelineColor(item){
        if (item.viewcount>0) return "green";
        return "grey"
      },
      itemColor(item){
        if (item.ziel_erreicht=="Y") return "green";
        if (!item.analyse && !item.empfehlung) return "orange"
        return "primary"
      },
      selectFile(file) {
        this.progress = 0;
        this.currentFile = file;
        this.upload(file)
      },
      clearFile(){
        this.currentFile = undefined
        this.progress = 0
        this.mediafile = null
      },
      async logView(item){
        await axios.post(process.env.VUE_APP_APIURL+"itemlog/"+item.ID,{},{
          headers: {
            'token': localStorage.getItem('token')
          }
        })
      },
      ansehen(item){
        this.logView(item)
        var url = process.env.VUE_APP_APIURL+"mediafile/"+item.ID+"/"+item.itemtoken
        if (item.filetype=="video"){
          this.current_video = url
          this.current_video_mime_type = item.filemimetype
          this.current_image = false
        }else if (item.filetype=="image"){
          this.current_video = false
          this.current_video_mime_type = false
          this.current_image = url
        }else{
          this.current_video = false
          this.current_video_mime_type = false
          this.current_image = false
        }
      },
      async upload(file) {
        if (!file) return 
        this.uploading = true;
        let formData = new FormData();
        formData.append("file", file);
        await axios.post(process.env.VUE_APP_APIURL+"item/"+this.id,formData,{
          headers: {
            'token': localStorage.getItem('token'),
            "Content-Type": "multipart/form-data"
          }
        }).then(result => {
          if (result.status=="200"){

              this.uploading = false;
              this.clearFile();
              if (result.data.result) this.items.push(result.data.result)
          }
        });
      },
      async loadUser(){
        await axios.get(process.env.VUE_APP_APIURL+"user/"+this.id,{
          headers: {
            'token': localStorage.getItem('token')
          }
        }).then(result => {
            if (result.status=="200"){
                this.user = result.data.result[0];
            }else if (result.status=="401"){
                this.$router.push('/login')
            }
        });
      },
      async loadItems(){
        await axios.get(process.env.VUE_APP_APIURL+"items/"+this.id,{
          headers: {
            'token': localStorage.getItem('token')
          }
        }).then(result => {
            if (result.status=="200"){
                this.items = result.data.result.items;
            }else if (result.status=="401"){
                this.$router.push('/login')
            }
        });
      },
      edituser(){
        this.$router.push('/edituser/'+this.user.ID)
      },
      editShowItem(item){
        if (this.isTrainer){
          this.$router.push('/edititem/'+this.user.ID+'/'+item.ID)
        }else{
          this.$router.push('/showitem/'+this.user.ID+'/'+item.ID)
        }
        
      },
    },
    mounted(){
      this.loadUser()
      this.loadItems()
    },
  }
</script>
<style scoped>
div>>>.v-timeline-item__body{
  top:-8px !important;
}
</style>