<template>
  <v-container>
    <v-text-field v-model="token" label="Token" required></v-text-field>
    <v-btn color="primary" @click="login" class="mx-0 mt-2" outlined>Anmelden</v-btn>
  </v-container>
</template>

<script>
  export default {
    name: 'login',
    data: () => ({
     token: null,
    }),
    methods:{
      login() {
        document.location.href = "/?token="+this.token
      },
    },
  }
</script>
