<template>
  <v-container>
   <v-list subheader two-line>
     <template v-for="(user,index) in userlist">
      <v-list-item :key="'U'+user.ID" @click="openUser(user.ID)">
        <v-list-item-avatar>
          <v-img v-if="user.bild" :src="user.bild"></v-img>
          <v-avatar size="40" v-else color="deep-purple accent-1">
            <span class="white--text text-h5">{{ user.name.substr(0,1)+user.vorname.substr(0,1) }}</span>
          </v-avatar>
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-title v-html="user.name+' '+user.vorname"></v-list-item-title>
          <v-list-item-subtitle v-html="user.email"></v-list-item-subtitle>
        </v-list-item-content>
        <v-list-item-action>
          <v-icon color="grey lighten-1">mdi-chevron-right</v-icon>
        </v-list-item-action>
      </v-list-item>
      <v-divider v-if="index < userlist.length - 1" :key="index"></v-divider>
     </template>
   </v-list>
  </v-container>
</template>

<script>

import axios from 'axios';

  export default {
    name: 'userlist',

    data: () => ({
      userlist:[],
    }),
    methods:{
      async loadUser(){
        await axios.get(process.env.VUE_APP_APIURL+"user",{
          headers: {
            'token': localStorage.getItem('token')
          }
        })
        .then(result => {
            if (result.status=="200"){
                this.userlist = result.data.result;
            }
        }).catch(() => {
          //this.$router.push('/login')
        });
      },
      openUser(id){
        this.$router.push('/user/'+id)
      }
    },
    mounted(){
      this.loadUser()
    },
  }
</script>
