<template>
  <v-app>
    <v-app-bar app color="primary" dark>
      <!--<div class="d-flex align-center">
        <v-app-bar-nav-icon></v-app-bar-nav-icon>
      </div>-->
      <v-toolbar-title>{{ title }}</v-toolbar-title>

      <v-spacer></v-spacer>

      <v-menu offset-y v-if="$route.path=='/home' && isTrainer">
        <template v-slot:activator="{ on, attrs }">
          <v-app-bar-nav-icon v-bind="attrs" v-on="on"></v-app-bar-nav-icon>
        </template>
        <v-list>
          <v-list-item><router-link class="routerlink black--text" to="/adduser"><v-list-item-title>Sportler anlegen</v-list-item-title></router-link></v-list-item>
          <v-list-item><router-link class="routerlink black--text" to="/sportdata_turnier_einlesen"><v-list-item-title>Sportdate Turnier einlesen</v-list-item-title></router-link></v-list-item>
        </v-list>
      </v-menu>
      <router-link class="routerlink" v-else-if="inited && $route.path.substr(0,9)=='/edititem'" :to="'/user/'+$route.params.userid"><v-btn text fab><v-icon>mdi-chevron-left</v-icon></v-btn></router-link>
      <router-link class="routerlink" v-else-if="inited && $route.path.substr(0,9)=='/showitem'" :to="'/user/'+$route.params.userid"><v-btn text fab><v-icon>mdi-chevron-left</v-icon></v-btn></router-link>
      <router-link class="routerlink" v-else-if="inited && $route.path.substr(0,9)=='/edituser'" :to="'/user/'+$route.params.id"><v-btn text fab><v-icon>mdi-chevron-left</v-icon></v-btn></router-link>
      <router-link class="routerlink" v-else-if="$route.path!='/home' && isTrainer" to="/home"><v-btn text fab><v-icon>mdi-home</v-icon></v-btn></router-link>
    </v-app-bar>
    <v-main>
      <router-view></router-view>
    </v-main>
    <v-footer class="text-caption text-center pl-5 mt-auto d-block py-0">Version {{ appversion }}</v-footer>
  </v-app>
</template>

<script>

import axios from 'axios';
import {version} from './../package'
export default {
  name: 'App',
  data: () => ({
    DEFAULT_TITLE : 'CoachMe',
    title: null,
    inited: false,
    appversion: version
  }),
  methods:{
      async init(){
        await axios.get(process.env.VUE_APP_APIURL+"init",{
          headers: {
            'token': localStorage.getItem('token')
          }
        })
        .then(result => {
            if (result.status=="200"){
              window.myuser = result.data.result;
              this.inited = true;
              if (window.myuser){
                if (this.$route.name!="Sportler"){
                  if (this.isTrainer){
                    this.$router.push('/home')
                  }else{
                    this.$router.push('/user/'+window.myuser.ID)
                  }
                }
              }else{
                this.$router.push('/login')
              }
            }
        }).catch(() => {
          this.$router.push('/login')
        });
      },
  },
  mounted(){
    this.init()
  },
  computed:{
    isTrainer(){
      if (this.inited && window.myuser) return window.myuser.isTrainer;
      return false;
    }
  },
  watch: {
    $route: {
      immediate: true,
      handler(to) {
          document.title = this.DEFAULT_TITLE+" - "+to.name|| this.DEFAULT_TITLE;
          this.title = document.title;
      }
    },
  }
};
</script>

<style>
.routerlink{
  text-decoration: none !important;
}
</style>