<template>
  <v-container>
    <v-snackbar centered color="error" :value="this.error" timeout="5000">{{ this.error }}</v-snackbar>
    <v-form>
      <v-select v-model="type" :items="['Sportler','Trainer']" label="Benutzer-Typ" required></v-select>
      <v-text-field v-model="name" :rules="nameRules" label="Name" required></v-text-field>
      <v-text-field v-model="vorname" :rules="nameRules" label="Vorname" required></v-text-field>
      <v-text-field v-model="email" :rules="emailRules" label="E-Mail" required></v-text-field>
      <div class="d-flex">
        <v-select style="max-width:70px" class="mr-3" :rules="telLandRules" v-model="tel_land" :items="tel_land_auswahl" label="Telefon" prefix="+" required></v-select>
        <v-text-field v-model="tel" :rules="telRules" required></v-text-field>
      </div>
      <v-file-input v-model="bild" label="Bild" accept="image/jpeg" truncate-length="50" prepend-icon="mdi-camera"></v-file-input>
      <v-btn color="primary" class="mt-5" block @click="save">Anlegen</v-btn>
    </v-form>
  </v-container>
</template>

<script>

import axios from 'axios';

  export default {
    name: 'useradd',

    data: () => ({
      name: "",
      vorname: "",
      email: "",
      tel_land: "",
      tel: "",
      tel_land_auswahl: ["","49","43"],
      type: "Sportler",
      bild: null,
      uploading: false,
      error: false,
      nameRules: [
        v => !!v || 'Angabe erforderlich',
      ],
      emailRules: [
        v => !!v || 'E-Mail ist erforderlich',
        v => /.+@.+\..+/.test(v) || 'E-Mail ist ungültig',
      ],
      telLandRules: [
        v => !!v || 'erforderlich',
        v => /[0-9]+/.test(v) || 'ungültig',
      ],
      telRules: [
        v => !!v || 'Telefonnummer ist erforderlich',
        v => /[0-9]+/.test(v) || 'Telefonnummer ist ungültig',
      ],
    }),
    computed:{

    },
    methods:{
      async save() {
        this.uploading = true;
        let formData = new FormData();
        formData.append("bild", this.bild);
        formData.append("name", this.name);
        formData.append("vorname", this.vorname);
        formData.append("email", this.email);
        formData.append("tel_land", this.tel_land);
        formData.append("tel", this.tel);
        formData.append("type", this.type);
        
        await axios.post(process.env.VUE_APP_APIURL+"user/",formData,{
          headers: {
            'token': localStorage.getItem('token'),
            "Content-Type": "multipart/form-data"
          }
        }).then(() => {
          this.uploading = false;
          this.$router.push('/home')
        }).catch((error) => {
          this.error = error.message
        })
      },
    },
    mounted(){

    },
  }
</script>
