<template>
  <v-container>

    <v-snackbar centered color="error" :value="this.error" timeout="5000">{{ this.error }}</v-snackbar>
    <v-snackbar centered color="success" :value="this.success" timeout="5000">{{ this.success }}</v-snackbar>
    <v-form>
      <div class="mx-auto text-center" v-if="avatar">
          <v-avatar size="200"><img :src="avatar"></v-avatar>
      </div>
      <v-select v-model="type" :items="['Sportler','Trainer']" label="Benutzer-Typ" required></v-select>
      <v-text-field v-model="name" :rules="nameRules" label="Name" required></v-text-field>
      <v-text-field v-model="vorname" :rules="nameRules" label="Vorname" required></v-text-field>
      <v-text-field v-model="email" :rules="emailRules" label="E-Mail" required></v-text-field>
      <div class="d-flex">
        <v-select style="max-width:70px" class="mr-3" :rules="telLandRules" v-model="tel_land" :items="tel_land_auswahl" label="Telefon" prefix="+" required></v-select>
        <v-text-field v-model="tel" :rules="telRules" required></v-text-field>
      </div>
      <v-file-input v-model="bild" @change="createBase64Image" label="Bild" accept="image/jpeg" truncate-length="50" prepend-icon="mdi-camera"></v-file-input>
      <div class="d-flex mt-5">
        <v-btn color="grey" dark @click="back">Abbrechen</v-btn>
        <v-spacer></v-spacer>
        <v-btn color="primary" @click="save">Speichern</v-btn>
      </div>
      <div class="d-flex mt-5">
        <v-btn color="error" @click="del">Löschen</v-btn>
        <v-spacer></v-spacer>
        <v-btn v-if="!loginlink" color="deep-purple accent-2" dark @click="copyLink"><v-icon left>mdi-clipboard-arrow-up-outline</v-icon> Zugangslink anzeigen</v-btn>
      </div>
      <div v-if="loginlink" class="mt-4" style="width:100%"><a :href="loginlink">{{ loginlink }}</a></div>
    </v-form>
  </v-container>
</template>

<script>

import functions from '@/mixins/functions';
import axios from 'axios';

  export default {
    name: "edituser",
    mixins:[functions],
    data: () => ({
      name: "",
      vorname: "",
      email: "",
      tel_land: "",
      tel: "",
      tel_land_auswahl: ["","49","43"],
      type: "Sportler",
      bild: null,
      avatar: null,
      uploading: false,
      error: false,
      success: false,
      loading: false,
      loginlink: false,
      nameRules: [
        v => !!v || 'Angabe erforderlich',
      ],
      emailRules: [
        v => !!v || 'E-Mail ist erforderlich',
        v => /.+@.+\..+/.test(v) || 'E-Mail ist ungültig',
      ],
      telLandRules: [
        v => !!v || 'erforderlich',
        v => /[0-9]+/.test(v) || 'ungültig',
      ],
      telRules: [
        v => !!v || 'Telefonnummer ist erforderlich',
        v => /[0-9]+/.test(v) || 'Telefonnummer ist ungültig',
      ],
    }),
    props:["id"],
    computed:{

    },
    methods:{
        back(){
            this.$router.push('/user/'+this.id)
        },
        createBase64Image(fileObject){
            if (!fileObject){
                return false;
            }
            const r = new FileReader();
            if (fileObject) this.loading = true;
            r.onload = (e) => {
                this.avatar = e.target.result
            }
            r.readAsDataURL(fileObject)
        },
        async load_user(){
            this.uploading = true;
            await axios.get(process.env.VUE_APP_APIURL+"user/"+this.id,{
                headers: {
                    'token': localStorage.getItem('token')
                }
            }).then((result) => {
                this.uploading = false;
                var u = result.data.result[0]
                this.name = u.name
                this.vorname = u.vorname
                this.email = u.email
                this.tel_land = u.tel_land
                this.tel = u.tel
                this.type = u.type
                this.avatar = u.bild
            }).catch((error) => {
                this.error = error.message
            })
        },
        async copyLink(){
            await axios.get(process.env.VUE_APP_APIURL+"userlink/"+this.id,{
            headers: {
                'token': localStorage.getItem('token')
            }
            }).then((result) => {
                this.success = false
                this.loginlink = result.data.result
                /*
                if (this.copyStrToClipboard(result.data.result)){
                    this.$nextTick(() => {this.success = "Link wurde in die Zwischenablage kopiert"})
                }else{
                    this.error = false
                    this.$nextTick(() => {this.error = "Link konnte nicht in die Zwischenablege kopiert werden"})
                }
                */
            }).catch((error) => {
                this.error = false
                this.$nextTick(() => {this.error = "FEHLER: "+error.message})
            })
        },
        async sendlink(){
            await axios.get(process.env.VUE_APP_APIURL+"userlink/"+this.id,{
            headers: {
                'token': localStorage.getItem('token')
            }
            }).then(() => {
                this.success = false
                this.$nextTick(() => {this.success = "Zugangsdaten wurde erfolgreich an "+this.email+" gesendet"})
            }).catch((error) => {
                this.error = false
                this.$nextTick(() => {this.error = "Zugangsdaten konnten nicht gesendet werden: "+error.message})
            })
        },
        async del(){
            await axios.delete(process.env.VUE_APP_APIURL+"user/"+this.id,{
            headers: {
                'token': localStorage.getItem('token')
            }
            }).then(() => {
                this.$router.push('/home')
            });
        },
        async save() {
            this.uploading = true;
            let formData = new FormData();
            formData.append("bild", this.bild);
            formData.append("name", this.name);
            formData.append("vorname", this.vorname);
            formData.append("email", this.email);
            formData.append("tel_land", this.tel_land);
            formData.append("tel", this.tel);
            formData.append("type", this.type);

            await axios.post(process.env.VUE_APP_APIURL+"edituser/"+this.id,formData,{
                headers: {
                    'token': localStorage.getItem('token'),
                    "Content-Type": "multipart/form-data"
                }
                }).then(() => {
                this.uploading = false;
                this.back()
            }).catch((error) => {
                this.error = error.message
            })
        },
    },
    mounted(){
        this.load_user()
    },
  }
</script>
