export default {
    methods:{
        mysqlToLocalDate (date) {
            if (!date) return null
            const [year, month, day] = date.split('-')
            return `${day}.${month}.${year}`
        },
        copyStrToClipboard(string){
            var el=document.createElement('input');
            el.setAttribute('style','position:absolute;top:-9999px');
            el.value=string;
            document.body.appendChild(el);
            var range = document.createRange();
            el.contentEditable=true;
            el.readOnly = false;
            range.selectNodeContents(el);
            var s=window.getSelection();
            s.removeAllRanges();
            s.addRange(range);
            el.setSelectionRange(0, 999999);
            document.execCommand('copy');
            el.remove();
        },
    }
};