<template>
  <v-container>
    <v-card>
      <v-card-title>{{ item.d }} {{ item.t }}</v-card-title>
      <div class="mx-auto text-center" v-if="item.thumb">
          <v-avatar size="200"><img :src="item.thumb"></v-avatar>
      </div>
      <v-card-text>{{ item.analyse }}</v-card-text>
      <v-card-text>{{ item.empfehlung }}</v-card-text>
    </v-card>
    <v-btn color="primary" class="mt-5" block @click="back">Zurück</v-btn>
  </v-container>
</template>

<script>

import axios from 'axios';
  export default {
    name: "showitem",
    data: () => ({
      item: {},
    }),
    props:["id","userid"],
    methods:{
      back(){
        this.$router.push('/user/'+this.userid)
      },
      async loadItem(){
        await axios.get(process.env.VUE_APP_APIURL+"item/"+this.id,{
          headers: {
            'token': localStorage.getItem('token')
          }
        }).then(result => {
            this.item = result.data.result;
        });
      },
    },
    mounted(){
      this.loadItem()
    },
  }
</script>
