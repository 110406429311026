<template>
  <v-container>
    <v-snackbar centered color="error" :value="this.error" timeout="5000">{{ this.error }}</v-snackbar>
    <v-snackbar centered color="success" :value="this.success" timeout="5000">{{ this.success }}</v-snackbar>
    <v-form ref="form" v-model="valid" lazy-validation>
      <div class="d-flex">
        <v-text-field v-model="url" label="URL" @click:clear="clear" required clearable></v-text-field>
        <v-spacer></v-spacer>
        <v-btn class="mt-3" :disabled="!url" @click="pruefen">Prüfen</v-btn>
      </div>
      
      <v-text-field disabled v-model="eventID" label="Event-ID" required></v-text-field>
      <v-text-field v-model="turnier" label="Turnier" :rules="nameRules" required maxlength="50"></v-text-field>
      <v-text-field v-model="datum" label="Datum" :rules="datumRules" required maxlength="10"></v-text-field>
      <v-btn color="primary" :loading="loading" :disabled="!valid" class="mt-5" block @click="einlesen">Einlesen</v-btn>
    </v-form>
  </v-container>
</template>

<script>

import axios from 'axios';

  export default {
    name: 'useradd',

    data: () => ({
      url: "https://www.sportdata.org/taekwondo/set-online/veranstaltung_info_main.php?active_menu=calendar&vernr=150#a_eventheadend",
      eventID: "",
      turnier: "",
      datum: "",
      loading: false,
      error: false,
      success: false,
      valid: false,
      nameRules: [
        v => !!v || 'Angabe erforderlich',
      ],
      datumRules: [
        v => !!v || 'Datum ist erforderlich',
        v => /[0-9]{2}\.[0-9]{2}\.[0-9]{4}/.test(v) || 'Datum ist ungültig (dd.mm.yyyy)',
      ],
    }),
    computed:{

    },
    methods:{
      validate () {
        this.$refs.form.validate()
      },
      clear(){
        this.eventID = ""
        this.turnier = ""
        this.datum = ""
        this.$refs.form.reset()
        this.$refs.form.resetValidation()
      },
      async pruefen() {
        await axios.post(process.env.VUE_APP_APIURL+"sportdata_pruefen/",{url:this.url},{
          headers: {
            'token': localStorage.getItem('token'),
            "Content-Type": "multipart/form-data"
          }
        }).then((r) => {
          r = r.data.result
          if(r.result){
            this.eventID = r.eventID
            this.turnier = r.turnier
            this.datum = r.datum
          }else{
            this.error = r.error
          }
          //this.$router.push('/home')
        }).catch((error) => {
          this.error = error.message
        })
      },
      async einlesen(){
        this.loading = true;
        await axios.post(process.env.VUE_APP_APIURL+"turnierimport/",{eventID:this.eventID,turnier:this.turnier,datum:this.datum},{
          headers: {
            'token': localStorage.getItem('token'),
            "Content-Type": "multipart/form-data"
          }
        }).then((r) => {
          this.loading = false;
          r = r.data
          if(r.result){
            this.success = r.num+" Datensätze eingelesen";
            this.url = ""
            this.clear()
          }else{
            this.error = r.error
          }
          //this.$router.push('/home')
        }).catch((error) => {
          this.error = error.message
        })
      }
    },
    mounted(){

    },
  }
</script>
